import React from "react";
import { graphql } from "gatsby";

// Components
import Layout from "../components/layout";
import Seo from "../components/seo/seo";
import Hero from "../components/hero";
import SideMenu from "../components/sideMenu";
import ExperienceItems from "../components/experience/experienceItems";

//Hooks
import useCategoriesList from "../hooks/useCategoriesMenu";

// Styles
import * as styles from "../templates/blogsStyles.module.scss";
import * as pageStyles from "./styles.module.scss";

//Constants
import API from "../constants/api";

const UserExperience = ({ data }) => {
  const { content, seo } = data.sanityExperience.tabs;
  const categories = data.allSanityExperienceCategory.nodes;
  const heroTitle = content?.hero?._rawHeroText.titleText;
  const heroSubtitle = content?.hero?._rawHeroText?.subtitleText;

  const categoriesList = useCategoriesList(API.experience, categories);

  return (
    <Layout>
      <Seo title={heroTitle} {...seo} />
      <Hero
        image={content.hero._rawBgImage}
        imageClassName={styles.heroImg}
        title={heroTitle}
        subTitle={heroSubtitle}
      />
      {content?.introText && (
        <div className={pageStyles.introWrapper}>
          <p>{content.introText}</p>
        </div>
      )}
      <div className={styles.mainContentWrapper}>
        <div className={styles.sideMenuWrapper}>
          <SideMenu
            getPathFunc={API.experienceCategory}
            list={categoriesList}
          />
        </div>
        <div className={styles.cardsWrapper}>
          <div className={styles.cards}>
            <ExperienceItems items={content.experience} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    sanityExperience {
      tabs {
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
        content {
          introText
          hero {
            _rawBgImage
            _rawHeroText
          }
          experience {
            _rawText(resolveReferences: { maxDepth: 10 })
            _rawImage(resolveReferences: { maxDepth: 10 })
            _key
            title
            subtitle
            category {
              introText
              title
            }
            slug {
              current
            }
          }
        }
      }
    }
    allSanityExperienceCategory {
      nodes {
        slug {
          current
        }
        title
      }
    }
  }
`;

export default UserExperience;
